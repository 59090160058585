<template>
  <div class="relative inline-block font-medium">
    <h2 class="type-font-salt-ss02 text-center text-charcoal">
      <span class="inline-block -rotate-[4deg]">take the</span><br />
      <span class="inline-block -rotate-6 pl-[0.3em]">pledge</span>
    </h2>
    <div class="pointer-events-none absolute inset-0">
      <PayloadIcon
        class="absolute bottom-[0.4em] left-[0em] h-[0.33em] text-purple"
        icon="SvgIconAttentionG"
      />
      <PayloadIcon
        class="absolute bottom-[1.78em] left-[1.1em] h-[0.55em] -rotate-[63deg] text-red-light"
        icon="SvgIconConfusionA"
      />
      <PayloadIcon
        class="absolute bottom-[1.8em] left-[3em] h-[0.42em] text-yellow"
        icon="SvgIconFrustrationA"
      />
      <PayloadIcon
        class="absolute -bottom-[0.45em] left-[1.75em] h-[0.4em] text-pink-dark"
        icon="SvgIconConfusionB"
      />
    </div>
  </div>
</template>
